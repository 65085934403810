import CONSTANT_TYPES from '../redux.types';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {fetchStationsFailed, fetchStationsSuccess} from './stations.actions';
import { firestore } from '../../firebase/firebase';

function* fetchStationStartAsync(){
    try{
        const stationsRef = yield firestore.collection('stations');
        const snapShot = yield stationsRef.get();
        const data = yield snapShot.docs.map(doc => doc.data());
        yield put(fetchStationsSuccess(data));
    }catch(e){
        yield put(fetchStationsFailed(e.message));
    }
}
function* fetchStationsUpdated(){
    try{
        yield firestore.collection('stations').onSnapshot(function*(snapShot) {
            const newData =  yield snapShot.docs.map(doc => doc.data())
             yield put(fetchStationsSuccess(newData));
             console.log("hhhh");
        })
    }catch(e){
        yield put(fetchStationsFailed(e.message))
    }
}

export function* onFetchStationStartAsync(){
    yield takeLatest(CONSTANT_TYPES.FETCH_STATIONS_START, fetchStationStartAsync);
}

export function* onFetchStationsForUpdated(){
    yield takeLatest(CONSTANT_TYPES.ADD_STATIONS, fetchStationsUpdated);
}