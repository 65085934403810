import CONSTANT_TYPES from "../redux.types";

export const SignInStart = (userData) => ({
    type: CONSTANT_TYPES.ADMIN_SIGN_IN_START,
    payload: userData
});

export const SignInSuccess = (user) => ({
    type: CONSTANT_TYPES.ADMIN_SIGN_IN_SUCCESS,
    payload : user
})
export const SignInFailed = (err) =>({
    type: CONSTANT_TYPES.ADMIN_SIGN_IN_FAILED,
    payload: err
})

export const checkUserSession = () => ({
    type: CONSTANT_TYPES.CHECK_ADMIN_SIGN_IN
})
export const SignOut = () =>({
    type: CONSTANT_TYPES.SIGN_OUT
})