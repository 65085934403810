import CONSTANT_TYPES from "../redux.types";
const INTIAL_STATE = {
    oils: null,
    err: null
}

const oilsReducer = (state = INTIAL_STATE, action) => {
    switch(action.type){
        case CONSTANT_TYPES.FETCH_OILS_SUCCESS:
            return {
                ...state,
                oils: action.payload
            }
        case CONSTANT_TYPES.FETCH_OILS_FAILED:
            return {
                ...state,
                err: action.payload
            }
        default:
            return state;
    }
}

export default oilsReducer;