import React, {useEffect, Suspense, lazy} from 'react';
import {connect} from 'react-redux';
import {selectCurrentUser} from './redux/user/user.selectors'
import {checkUserSession} from './redux/user/user.actions';
import Spinner from './components/Spinner/Spinner.component';
import {Route, Switch, Redirect} from 'react-router-dom';
import './App.css';

const Homepage = lazy(() => import('./pages/Homepage/Homepage.component'));
const Station = lazy(() => import('./pages/Station/Station.component'));
const GiftList = lazy(() => import('./pages/Giftlist/Giftlist.component'));
const OilList = lazy(() => import('./pages/Oillist/Oillist.component'));
const Fildelite = lazy(() => import('./pages/Fidelite/Fidelite.component'));
const Clients = lazy(() => import('./pages/Clients/Clients.component'));
const Login = lazy(() => import('./pages/Login/Login.component'));

function App({currentUser, checkUserSession}) {
 useEffect(() => {
  checkUserSession()
 }, [])
  
  return (
    <Suspense fallback={<Spinner />}>
    <div className="App">
             <Route exact path={["/", "/home"]} render={() => currentUser ? <Homepage /> : <Redirect to="/login" />}/>
             <Route exact path="/login" render={() => currentUser ? <Redirect to="/" /> : <Login />} />
             <Route exact path="/station" render={() => currentUser ? <Station /> : <Redirect to="/login" />} />
            <Route exact path="/cadeaux" render={() => currentUser ? <GiftList /> : <Redirect to="/login" />}/>
            <Route exact path="/huiles" render={() => currentUser ? <OilList /> : <Redirect to="/login" />} />
            <Route exact path="/fidelite" render={() => currentUser ? <Fildelite /> : <Redirect to="/login" />} />
            <Route exact path="/participants" render={() => currentUser ? <Clients /> : <Redirect to="/login" /> }/>
            

        
      
      

    </div>
    </Suspense>
  );
}

const mapStateToProps = state =>({
  currentUser : selectCurrentUser(state)
})
const mapDispatchToProps = dispatch =>({
  checkUserSession : () => dispatch(checkUserSession())
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
