const CONSTANT_TYPES = {
    'ADMIN_SIGN_IN_START':'ADMIN_SIGN_IN_START',
    'ADMIN_SIGN_IN_SUCCESS':'ADMIN_SIGN_IN_SUCCESS',
    'ADMIN_SIGN_IN_FAILED':'ADMIN_SIGN_IN_FAILED',
    'CHECK_ADMIN_SIGN_IN' : 'CHECK_ADMIN_SIGN_IN',
    'SIGN_OUT' : 'SIGN_OUT',
    'FETCH_CLIENTS_START' : 'FETCH_CLIENTS_START',
    'FETCH_CLIENTS_SUCCESS' :'FETCH_CLIENTS_SUCCESS',
    'FETCH_CLIENTS_FAILED' : 'FETCH_CLIENTS_FAILED',
    'FETCH_STATIONS_FAILED': 'FETCH_STATIONS_FAILED',
    'FETCH_STATIONS_START' : 'FETCH_STATIONS_START',
    'FETCH_STATIONS_SUCCESS': 'FETCH_STATIONS_SUCCESS',
    'FETCH_GIFTS_START': 'FETCH_GIFTS_START',
    'FETCH_GIFTS_SUCCESS': 'FETCH_GIFTS_SUCCESS',
    'FETCH_GIFTS_FAILED': 'FETCH_GIFTS_FAILED',
    'FETCH_OILS_START': 'FETCH_OILS_START',
    'FETCH_OILS_SUCCESS': 'FETCH_OILS_SUCCESS',
    'FETCH_OILS_FAILED': 'FETCH_OILS_FAILED',
    'ADD_STATIONS' : 'ADD_STATIONS',
    };
export default CONSTANT_TYPES;