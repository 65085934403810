import {combineReducers} from 'redux';
import userReducer from './user/user.reducer';
import clientsReducer from './clients/clients.reducer';
import stationReducer from './stations/stations.reducer';
import giftReducer from './gifts/gifts.reducer';
import oilsReducer from './huiles/huiles.reducer';

const rootReducer = combineReducers({
    user: userReducer,
    clientsReducer,
    stationReducer,
    giftReducer,
    oilsReducer

})
export default rootReducer;