import {all, call} from 'redux-saga/effects';
import {onSignUpWithEmailAndPasswird, onCheckUserSession} from './user/user.sagas';
import {onFetchClients} from './clients/clients.sagas';
import {onFetchStationStartAsync, onFetchStationsForUpdated} from './stations/stations.sagas';
import {onFetchGifts} from './gifts/gifts.sagas';
import {onFetchOils} from './huiles/huiles.sagas';
function* rootSaga(){
    yield all([
        call(onSignUpWithEmailAndPasswird), 
        call(onCheckUserSession), 
        call(onFetchClients), 
        call(onFetchStationStartAsync), 
        call(onFetchStationsForUpdated),
        call(onFetchGifts),
        call(onFetchOils)
    ]);
}
export default rootSaga;