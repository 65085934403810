import CONSTANT_TYPES from "../redux.types";

const INITIAL_STATE = {
    currentUser: null,
    err: null
}

const userReducer = (state= INITIAL_STATE, action) =>{
    switch(action.type){
        case CONSTANT_TYPES.ADMIN_SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload
            }
        case CONSTANT_TYPES.ADMIN_SIGN_IN_FAILED:
            return{
                ...state,
                err: action.payload
            }
        case CONSTANT_TYPES.SIGN_OUT:
            return {
                ...state,
                currentUser : null
            }
        default:
            return state;
    }

}
export default userReducer;