import CONSTANT_TYPES from '../redux.types';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {fetchhClienstSuccess, fetchhClienstFailed} from './clients.ations';
import { firestore } from '../../firebase/firebase';

function* fetchClientsStartAsync(){
    try{
        const clientsRef = yield firestore.collection('clients');
        const snapShot = yield clientsRef.get();
        const data = yield snapShot.docs.map(doc => doc.data());
        yield put(fetchhClienstSuccess(data));
    }catch(e){
        yield put(fetchhClienstFailed(e.message));
    }
}

export function* onFetchClients(){
    yield takeLatest(CONSTANT_TYPES.FETCH_CLIENTS_START, fetchClientsStartAsync);

}