import CONSTANT_TYPES from '../redux.types';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {firestore} from '../../firebase/firebase';
import {fetchOilsSuccess, fetchOilsFailed} from './huiles.actions';

function* fetchOilsStartAsync(){
    try{
        const giftsRef = yield firestore.collection('huiles');
        const snapShot = yield giftsRef.get();
        const data = yield snapShot.docs.map(doc => doc.data());
        yield put(fetchOilsSuccess(data));
    }catch(e){
        yield put(fetchOilsFailed(e.message));
    }
}

export function* onFetchOils(){
    yield takeLatest(CONSTANT_TYPES.FETCH_OILS_START, fetchOilsStartAsync);

}