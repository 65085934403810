import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { Data } from '@react-google-maps/api';

 const config = {
    apiKey: "AIzaSyDRpsN2GAZvN_QMHTe5HudF9FTWvdz1FTM",
    authDomain: "vivo-5ba49.firebaseapp.com",
    projectId: "vivo-5ba49",
    storageBucket: "vivo-5ba49.appspot.com",
    messagingSenderId: "596914657048",
    appId: "1:596914657048:web:ada4db6fd11853ea00b06d"
  };
  firebase.initializeApp(config);
  export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();


  export const createUserWithEmailAndPasswords = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`admin/${userAuth.uid}`);
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
        const { email } = userAuth;
        const createAt = new Date();
  
        try {
  
            await userRef.set({
              id: snapShot.id,
              email,
              createAt,
              privilege: 'high',
              ...additionalData
            })
  
        } catch (e) {
            alert("Error here : " + e.message)
        }
    }

  
  
    return userRef;
  }

  export const LoginForAdmin = async (userAuth) =>{
    if (!userAuth) return;
    const adminRef = await firestore.doc(`admin/${userAuth.uid}`);
    const snapShot  = await adminRef.get();
    if(!snapShot.exists){
      console.log('ereur');
      return;
    }

    return adminRef;
    


  }

  export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth)
        }, reject)
    })
  }

  export const updateVideo = async (data) =>{
    const collectionRef= firestore.collection('videos');
    const docRef = await collectionRef.doc(`${data.id}`);
    const snapShot = await docRef.get();
    if(snapShot.exists){
      const {url, id} = data;
      try{
        await docRef.update({
          'id' : id,
          'url' : url
        })
      }catch(e)
      {console.log(e.message)}
    }


  }
  export const deleteDocument = async (collectionKey, data) =>{
    const collectionRef= firestore.collection(collectionKey); 
    const docRef = await collectionRef.doc(`${data.id}`);
    const snapShot = await docRef.get();
    if(snapShot.exists){
      try{
        await docRef.delete();
      }catch(e){
        console.log(e.message);
      }
    }
  }

 export const updateDocument = async (collectionKey, data) =>{
   console.log(data);
   const km = data.km ? data.km : 0;
   const collectionRef= firestore.collection(collectionKey);
   const docRef = await collectionRef.doc(`${data.id}`);
   const snapShot = await docRef.get();
   if(snapShot.exists){
     const {nameOfProduct, descriptionOfProduct, urlImage, km} = data;
     try{
       await docRef.update({
         'name' : nameOfProduct,
         'description' : descriptionOfProduct,
         'url' : urlImage,
         'km': km
       })
     }catch(e)
     {console.log(e.message)}
   }

 }

  export const createDocumentsForCollection = async (collectionKey, data) =>{
    const collectionRef = firestore.collection(collectionKey);
    const docRef = collectionRef.doc();
    const snapShot = await docRef.get();
    const createdAt = new Date();
    try{
     await docRef.set({
       id: snapShot.id,
       createdAt,
       ...data
     })

    }catch(e){
      console.log(e.message);
    }

  }
  
