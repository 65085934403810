import CONSTANT_TYPES from "../redux.types";

export const fetchStationsStart = () =>({
    type: CONSTANT_TYPES.FETCH_STATIONS_START

})

export const fetchStationsSuccess = (stations) =>({
    type: CONSTANT_TYPES.FETCH_STATIONS_SUCCESS,
    payload: stations
    
})

export const fetchStationsFailed = (err) =>({
    type: CONSTANT_TYPES.FETCH_STATIONS_FAILED,
    payload: err
    
})
export const addStations = () =>({
    type:CONSTANT_TYPES.ADD_STATIONS
})

export const deleteStations = () =>({

})