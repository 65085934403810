import CONSTANT_TYPES from "../redux.types";

const INTIAL_STATE = {
    stations: null,
    err: null
}

const stationReducer = (state= INTIAL_STATE, action) =>{
    switch (action.type) {
        case CONSTANT_TYPES.FETCH_STATIONS_SUCCESS:
            return {
                ...state,
                stations: action.payload
            }
        case CONSTANT_TYPES.FETCH_STATIONS_FAILED:
            return {
                ...state,
                err : action.payload
            }
        default:
            return state;
    }
}

export default stationReducer;