import React from 'react';
import styled, {keyframes} from 'styled-components';

const Spinner = () => {
    return(
        <SpinnerContainer>
          <SpinnerLoader />

        </SpinnerContainer>

    )
}
export default Spinner;
const lds = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }

`;

const SpinnerContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
`;
const SpinnerLoader = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  &::after{
    content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #E84B4B;
  border-color: #E84B4B transparent #E84B4B transparent;
  animation: ${lds} 1.2s linear infinite;
  }
`;