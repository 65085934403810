import CONSTANT_TYPES from '../redux.types';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {SignInFailed, SignInSuccess} from './user.actions';
import {createUserWithEmailAndPasswords, auth, getCurrentUser} from '../../firebase/firebase';

export function* signUpWithEmailAndPassword({ payload: {email, password} }){
    
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        const userRef = yield call(createUserWithEmailAndPasswords, user);
        const snapShot = yield userRef.get();
        yield put(SignInSuccess({
            id: snapShot.id,
            ...snapShot.data()
        }))

    } catch (e) {
        yield put(SignInFailed(e.message))
    }
}

export function* checkUserSession() {
    try {
        const userAuth = yield  getCurrentUser();
        if (!userAuth) return;
        const userRef = yield call(createUserWithEmailAndPasswords, userAuth);
        const snapShot = yield userRef.get();
        yield put(SignInSuccess({
            id: snapShot.id,
            ...snapShot.data(),

        }))
    } catch (e) {
        yield put(SignInFailed(e.message))
    }
}

export function* onCheckUserSession() {
    yield takeLatest(CONSTANT_TYPES.CHECK_ADMIN_SIGN_IN, checkUserSession)
}
export function* onSignUpWithEmailAndPasswird() {
    yield takeLatest(CONSTANT_TYPES.ADMIN_SIGN_IN_START, signUpWithEmailAndPassword);
}
