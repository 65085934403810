import CONSTANT_TYPES from "../redux.types";

export const fetchOilsStart = () => ({
    type: CONSTANT_TYPES.FETCH_OILS_START
})

export const fetchOilsSuccess = (oils) => ({
    type: CONSTANT_TYPES.FETCH_OILS_SUCCESS,
    payload : oils
})

export const fetchOilsFailed= (err) => ({
    type: CONSTANT_TYPES.FETCH_OILS_FAILED,
    payload : err
})