import CONSTANT_TYPES from "../redux.types";

export const fetchhClienstStart = () =>({
    type: CONSTANT_TYPES.FETCH_CLIENTS_START
})
export const fetchhClienstSuccess = (clients) =>({
    type: CONSTANT_TYPES.FETCH_CLIENTS_SUCCESS,
    payload: clients
})
export const fetchhClienstFailed = (err) =>({
    type: CONSTANT_TYPES.FETCH_CLIENTS_FAILED,
    payload: err
})