import CONSTANT_TYPES from "../redux.types";

export const fetchGiftsStart = () =>({
    type: CONSTANT_TYPES.FETCH_GIFTS_START
})

export const fetchGiftsSuccess = (gifts) =>({
    type: CONSTANT_TYPES.FETCH_GIFTS_SUCCESS,
    payload: gifts
})

export const fetchGiftsFailed = (err) =>({
    type: CONSTANT_TYPES.FETCH_GIFTS_FAILED,
    payload: err
})
export const updateGift = () =>({
    type: CONSTANT_TYPES.UPDATE_GIFT
})