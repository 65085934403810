import CONSTANT_TYPES from '../redux.types';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import {firestore} from '../../firebase/firebase';
import {fetchGiftsSuccess, fetchGiftsFailed} from './gifts.actions';

function* fetchGiftsStartAsync(){
    try{
        const giftsRef = yield firestore.collection('cadeaux');
        const snapShot = yield giftsRef.get();
        const data = yield snapShot.docs.map(doc => doc.data());
        yield put(fetchGiftsSuccess(data));
    }catch(e){
        yield put(fetchGiftsFailed(e.message));
    }
}

export function* onFetchGifts(){
    yield takeLatest(CONSTANT_TYPES.FETCH_GIFTS_START, fetchGiftsStartAsync);

}