import CONSTANT_TYPES from "../redux.types";

const INITIAL_STATE = {
    clients: null,
    err: null
}

const clientsReducer = (state=INITIAL_STATE, action) =>{
    switch(action.type){
        case CONSTANT_TYPES.FETCH_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload
            }
        case CONSTANT_TYPES.FETCH_CLIENTS_FAILED:
            return {
                ...state,
                err: action.payload
            }
        default:
            return state;
    }
}

export default clientsReducer;