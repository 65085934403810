import CONSTANT_TYPES from "../redux.types";

const INITIAL_STATE = {
    gifts: null,
    err: null
}

const giftReducer = (state = INITIAL_STATE, action) =>{
  switch(action.type){
      case CONSTANT_TYPES.FETCH_GIFTS_SUCCESS:
          return {
              ...state,
              gifts : action.payload
          }
      case CONSTANT_TYPES.FETCH_GIFTS_FAILED:
          return {
              ...state,
              err: action.payload
          }
     default:
         return state;
  }
}

export default giftReducer;